import { useCallback, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { CssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';

import { Container } from '@mui/joy';
import { LocaleProvider, useNavigateKeepParams, useSentrySetup, useSurveyTheme } from 'hooks';
import { getAPI, postAPI } from 'lib/APIHelpers';

import { AppSkeleton } from 'AppSkeleton';
import { PageFooter } from 'elements/PageFooter';
import { TimeoutModal } from 'elements/modal';
import { useTimeoutModal } from 'elements/modal/useTimeoutModal';
import { CONTAINER_WIDTH, KIOSK_TIMEOUT_VALID_STATES, RESPONDENT_SURVEY_STATUS_VALUES } from 'lib/Enum';

export const SurveyOutletWrapper = () => {
  const [showWelcomePage, setShowWelcomePage] = useState(false);

  const { feedbackID } = useParams();
  const navigateKeepParams = useNavigateKeepParams();
  const queryClient = useQueryClient();
  const { isPending, data, isFetching } = useQuery({
    queryKey: ['getSurveyData', feedbackID],
    queryFn: () => getAPI(`api/v2/feedback/${feedbackID}`),
    throwOnError: true
  });

  const {
    survey: { name: survey_name } = {}
  } = data || {};

  const { en } = survey_name || {};
  if (en) document.title = en;

  const {
    survey: {
      account_organisation_name,
      colour,
      end_date,
      id: survey_id,
      uuid: survey_uuid,
      kiosk_mode_enabled,
      enable_response_export,
      logo: {
        intro_64_x1_5: {
          url: image
        } = {}
      } = {},
      locales,
      min_responses_for_report,
      survey_locales,
      show_locale_page,
      start_date,
      response_option_required_comment_enabled,
      enable_new_survey_taking_flow,
      browser_lock
    } = {},
    respondent: {
      locale,
      preview,
      self_reporter,
      anonymous,
      survey_status: respondent_survey_status = RESPONDENT_SURVEY_STATUS_VALUES.NOT_STARTED
    } = {},
    kiosk_mode_inactivity_timeout,
    redirect_url
  } = data || {};

  const kiosk_url_param = new URLSearchParams(window.location.search).get('kiosk_mode') == '1';
  const is_kiosk_mode = kiosk_mode_enabled && kiosk_url_param;
  const kiosk_mode_param = is_kiosk_mode ? '?kiosk_mode=1' : '';

  const oldSurveyTakingInProgress = !enable_new_survey_taking_flow
    && respondent_survey_status == RESPONDENT_SURVEY_STATUS_VALUES.IN_PROGRESS;

  const kioskTimeoutSeconds = useMemo(
    () => (
      is_kiosk_mode && KIOSK_TIMEOUT_VALID_STATES.includes(respondent_survey_status)
        ? kiosk_mode_inactivity_timeout
        : undefined
    ),
    [respondent_survey_status, is_kiosk_mode, kiosk_mode_inactivity_timeout]
  );

  const {
    modalOpen,
    setModalOpen
  } = useTimeoutModal({ timeout: kioskTimeoutSeconds });

  const { joyTheme } = useSurveyTheme({ colour });

  useSentrySetup({
    feedbackID,
    account_organisation_name,
    survey_name,
    survey_id,
    start_date,
    end_date,
    locale,
    preview,
    is_kiosk_mode
  });

  const navigateToWelcome = useCallback(
    () => setShowWelcomePage(true),
    []
  );

  const setRespondentStatus = useCallback(
    (status) => {
      queryClient.setQueryData(
        ['getSurveyData', feedbackID], (old_state) => ({
          ...old_state,
          respondent: {
            ...old_state.respondent,
            survey_status: status
          }
        })
      );
    },
    [feedbackID, queryClient]
  );

  const setSessionToken = useCallback(
    (token) => {
      const sessionTokenKey = `session_token_${feedbackID}`;
      const existingToken = localStorage.getItem(sessionTokenKey);
      if (existingToken) return;
      localStorage.setItem(sessionTokenKey, token);
    },
    [feedbackID]
  );

  const onStartSurvey = useCallback(
    async (params) => {
      const { redirect_url, respondent } = await postAPI(
        `api/v2/feedback/${feedbackID}/start_survey${kiosk_mode_param}`,
        { respondent: { ...params } }
      );

      if (redirect_url) {
        return window.location.href = redirect_url;
      }

      if (browser_lock) {
        const  { session_token } = respondent || {};
        if (session_token) {
          setSessionToken(session_token);
        }
      }

      setRespondentStatus(RESPONDENT_SURVEY_STATUS_VALUES.IN_PROGRESS);
      navigateKeepParams(`/${feedbackID}/questions`);
    },
    [
      feedbackID,
      kiosk_mode_param,
      browser_lock,
      navigateKeepParams,
      setRespondentStatus,
      setSessionToken
    ]
  );

  const sharedState = {
    survey_name,
    survey_uuid,
    account_organisation_name,
    image,
    self_reporter,
    anonymous,
    is_kiosk_mode,
    enable_response_export,
    locales,
    survey_locales,
    show_locale_page,
    end_date,
    min_responses_for_report,
    kiosk_mode_inactivity_timeout,
    oldSurveyTakingInProgress,
    redirect_url,
    showWelcomePage,
    navigateToWelcome,
    onStartSurvey,
    setRespondentStatus,
    response_option_required_comment_enabled,
    respondent_survey_status,
    browser_lock
  };

  if (isPending || isFetching) return <AppSkeleton />;

  return (
    <LocaleProvider>
      <CssVarsProvider theme={joyTheme}>
        <CssBaseline />
        <div className={'app'}>
          <Container
            maxWidth={false}
            sx={{
              px: 3,
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              maxWidth: CONTAINER_WIDTH
            }}
          >
            <Outlet context={sharedState} />
          </Container>
          <PageFooter
            is_kiosk_mode={is_kiosk_mode}
          />
        </div>
        <TimeoutModal
          feedbackID={feedbackID}
          survey_uuid={survey_uuid}
          open={modalOpen}
          onContinue={setModalOpen}
        />
      </CssVarsProvider>
    </LocaleProvider>
  );
};
