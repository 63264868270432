import { Button, Modal, ModalClose, ModalDialog, Stack, Typography } from '@mui/joy';

export const ModalWrapper = ({
  open,
  title,
  body,
  cancelButton,
  continueButton,
  secondaryButton,
  onClose,
  onClick,
  onSecondaryClick
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <ModalDialog
        size={'lg'}
        aria-labelledby={'modal-title'}
        aria-describedby={'modal-description'}
        data-component={'modal-dialog'}
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            top: 'unset',
            bottom: 0,
            left: 0,
            right: 0,
            borderRadius: 0,
            transform: 'none',
            maxWidth: 'unset',
            overflowY: 'scroll'
          },
          [theme.breakpoints.up('md')]: {
            px: 5,
            maxWidth: 900
          },
        })}
      >
        <ModalClose
          aria-label={'close modal'}
          variant={'plain'} sx={{ m: 1 }}
        />
        <Stack
          direction={'column'}
          spacing={5}
          alignItems={'center'}
          sx={{
            mt: { xs: 8, md: 13 },
            mb: { xs: 5, md: 8 },
            textAlign: 'center'
          }}
        >
          <Typography
            level={'h1'}
            sx={{
              fontWeight: 400,
              fontSize: { xs: 32, md: 48 }
            }}
            id={'modal-title'}
          >
            {title}
          </Typography>
          <div id={'modal-description'}>
            {body}
          </div>
          <Stack
            spacing={2}
            direction={{ xs: 'column', md: 'row' }}
          >
            <Button
              variant={'outlined'}
              color={'neutral'}
              onClick={onClose}
              size={'lg'}
              data-component={'cancel-button'}
            >
              {cancelButton}
            </Button>
            {secondaryButton && onSecondaryClick && (
              <Button
                variant={'outlined'}
                color={'neutral'}
                size={'lg'}
                onClick={onSecondaryClick}
              >
                {secondaryButton}
              </Button>
            )}
            <Button
              size={'lg'}
              onClick={onClick}
            >
              {continueButton}
            </Button>
          </Stack>
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
